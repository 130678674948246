import React, { useState } from 'react';
import { register, signIn } from "../modules/auth";
import { Form, Tab, Dimmer, Loader } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

const Signin = props => {
  const [redirect, setRedirect] = useState(false)
  const [loader, setLoader] = useState(false)
  const [signin, setSignin] = useState(["hidden", -2000, 0])

  const submitFormHandler = async event => {
    event.preventDefault();
    setLoader(true)
    let response = await register(
      event.target.email.value,
      event.target.username.value,
      event.target.password.value,
      event.target.passCon.value
    );
    if (response.status === 200) {
      setSignin(["hidden", -2000, 0])
      setLoader(false)
      props.setUser(response.data.data)
      setRedirect(true)
      alert(`Welcome ${response.data.data.nickname}`)
    } else {
      setLoader(false)
      alert(response)
    }
  }

  const submitSignInHandler = async event => {
    setSignin(["hidden", -2000, 0])
    event.preventDefault();
    setLoader(true)
    let response = await signIn(event.target.email.value, event.target.password.value)
    if (response.status === 200) {
      setLoader(false)
      props.setUser(response.data.data)
      sessionStorage.setItem('user', JSON.stringify(response.data.data))
      setRedirect(true)
    } else {
      setLoader(false)
      alert(response)
    }
  }

  const panes = [
    { 
      menuItem: 'SignIn', render: () => (
        <Tab.Pane>
          <> 
            <i onClick={() => setSignin(["hidden", -2000, 0])} className="x icon icon"></i>
            <div>
              <img src="/TradeLogs.png" alt="TradeLogs logo"/>
              <h4>
                Sign In  
              </h4>
            </div>
            <div className="form">
              <Form onSubmit={submitSignInHandler}>
                <br/>
                <input name="email" type="email" id="email" placeholder="Email"></input>
                <br/>
                <input name="password" type="password" id="password" placeholder="Password"></input>
                <br/>
                <button className="btn btn-primary" id="submit" type="submit">Sign In</button>
              </Form>
            </div>
          </>
        </Tab.Pane> 
      )
    },
    { 
      menuItem: 'Register', render: () => (
        <Tab.Pane>
          <>
            <i onClick={() => setSignin(["hidden", -2000, 0])} className="x icon icon"></i>
            <div>
              <img src="/TradeLogs.png"  alt="TradeLogs Logo"/>
              <h4>
                Register  
              </h4>
            </div>
            <div className="form">
              <Form onSubmit={submitFormHandler} >
                <br/>
                <input placeholder="Email" name="email" type="email" id="email"></input>
                <br/>
                <input placeholder="Username" name="username" type="text" id="username"></input>
                <br/>
                <input  placeholder="Password" name="password" type="password" id="password"></input>
                <br/>
                <input  placeholder="Confirm Password" name="passCon" type="password" id="passCon"></input>
                <br/>
                <button className="btn btn-primary" id="submit" type="submit" >Sign Up</button>
              </Form>
            </div>
          </>
        </Tab.Pane> 
      )
    }
  ]

  return (
    <>
      <header className="container-fluid text-center header-image">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <nav class="navbar navbar-expand navbar-light static-top">
              <div className='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100'>
                <div class="input-group">
                  <div class="input-group-append">
                    {/* left side of nav bar here */}
                    
                  </div>
                </div>
              </div>
              {/* <!-- Topbar Navbar --> */}
              <ul class="navbar-nav ml-auto">
                <li class="nav-item mx-2">
                  <a className='white' href='/terms'><h6>TERMS OF SERVICE</h6></a>
                </li>
                <li class="nav-item mx-2">
                  <a className='white' href='/privacy'><h6>PRIVACY POLICY</h6></a>
                </li>
                <li class="nav-item mx-2">
                  <a className='white' href='/howto'><h6>HOW TO</h6></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='overlay'>
          <h1>Quick. Easy. Tradelogs.</h1>
          <p>Dynamic representations of your trade data</p>
          <button onClick={() => setSignin(["visible", 4000, 1])} className="big ui button">Sign In</button>
          <div className="container-flex">
          <div className="header-grid">
            <div className="border-right">
              <div>
                  <h6>PROFIT CHART</h6>
                  <p>Gross or Net</p>
              </div>
            </div>
            <div className="border-right">
              <div >
                  <h6>FEES</h6>
                  <p>Fee tracking</p>
              </div>
            </div>
            <div className="border-right">
              <div>
                  <h6>BREAKDOWN</h6>
                  <p>Your statistics</p>
              </div>
            </div>
            <div className="border-right">
              <div>
                  <h6>STRATEGIES</h6>
                  <p>Save, display, track</p>
              </div>
            </div>
            <div className="no-border-right">
              <div>
                  <h6>GAP STATS</h6>
                  <p>Stock History Statistics</p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </header>

        <div className="container">
          <div>
            {redirect === true && <Redirect to='/panes'/>}
            <div style={{zIndex: `${signin[1]}`}} className="signin container">
              <div style={
                {visibility: `${signin[0]}`, 
                opacity: `${signin[2]}`, 
                zIndex: `${signin[1]}`}
                }
                className="signin-box">
                <Tab panes={panes} />
              </div>
            </div>
          </div>
        </div>
      {loader === true && (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    userAttrs: state.userAttrs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: data => {
      dispatch({ type: "SET_USER", payload: data });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);