import React from 'react';
import Panes from './components/Panes'
import Signin from './components/Signin'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "@stripe/stripe-js"
import Receipt from './components/Receipt';
import Terms from './components/Terms';
import HowTo from './components/HowTo';
import Privacy from './components/Privacy';
// Import Bootstrap core CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Import custom CSS for SB Admin 2
import 'startbootstrap-sb-admin-2/css/sb-admin-2.min.css';

// Import FontAwesome and other dependencies if needed
import '@fortawesome/fontawesome-free/css/all.min.css';

// Optional: Import Bootstrap JS (for components that require JavaScript, like modals and tooltips)
import 'bootstrap/dist/js/bootstrap.bundle.min';


const App = () => {
  return (
    <div className="App">
      <div className="main">
			<BrowserRouter>
        <Switch>
          <Route exact path="/" component={Signin} />
          <Route exact path="/panes" component={Panes} />
          <Route exact path="/receipt" component={Receipt} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/howto" component={HowTo} />
        </Switch>
      </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
