import React from 'react';

const Privacy = () => {
  
  return (
    <div id='content'>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <nav class="navbar navbar-expand navbar-light static-top">
              <div className='d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100'>
                <div class="input-group">
                  <div class="input-group-append">
                    {/* left side of nav bar here */}
                    <div style={{width: "30%", minWidth: "150px", marginLeft: "-1em"}}>
                      <a href="/"><img src="/TradeLogs.png"  alt="TradeLogs Logo"/></a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Topbar Navbar --> */}
              <ul class="navbar-nav ml-auto">
                <li class="nav-item mx-4">
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='container terms'>
            <h4 className='text-gray-800'>TradeLogs - Privacy Policy</h4>

            <h5 className='text-gray-800'>Introduction</h5>
            <p>TradeLogs ("we", "us", or "our") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and store your data.</p>
            
            <h5 className='text-gray-800'>Data We Collect</h5>
            <p>We only collect essential data for providing our service:</p>
            <ul>
                <li><strong>Account Data:</strong> Email address, username, and password.</li>
                <li><strong>Trade Data:</strong> Data that you voluntarily upload for visualization purposes.</li>
                <li><strong>Payment Data:</strong> We do not store payment details; transactions are securely processed by Stripe.</li>
            </ul>
            
            <h5 className='text-gray-800'>How We Use Your Data</h5>
            <p>We use your data to:</p>
            <ul>
                <li>Provide and improve our services.</li>
                <li>Process payments via Stripe.</li>
                <li>Communicate with you about updates or account-related issues.</li>
            </ul>
            
            <h5 className='text-gray-800'>How Your Data is Stored</h5>
            <p>Your login credentials are securely stored and encrypted. Trade data is stored on our servers but is not shared with third parties. Payment data is handled by Stripe and subject to their Privacy Policy.</p>
            
            <h5 className='text-gray-800'>Your Data Rights</h5>
            <p>If you are in the EU (GDPR applies), you have the right to:</p>
            <ul>
                <li>Access, correct, or delete your personal data.</li>
                <li>Object to data processing or request data portability.</li>
                <li>Withdraw consent for marketing emails.</li>
            </ul>
            <p>To exercise these rights, contact us at <strong>[your email]</strong>.</p>
            
            <h5 className='text-gray-800'>Data Security</h5>
            <p>We take reasonable steps to protect your data, but no system is 100% secure. Use strong passwords and report any suspicious activity.</p>
            
            <h5 className='text-gray-800'>Third-Party Services</h5>
            <p>We do not use cookies or third-party tracking services. Payments are processed through Stripe, which operates under its own security and privacy policies.</p>
            
            <h5 className='text-gray-800'>Changes to This Policy</h5>
            <p>We may update this Privacy Policy from time to time. Continued use of TradeLogs means you accept any updates.</p>
            
            <h5 className='text-gray-800'>Contact Us</h5>
            <p>For any questions or concerns, email <strong>tradelogs@gmail.com</strong>.</p>
        </div>
        <footer class="sticky-footer bg-white mt-4">
          <div class="container my-auto">
            <div class="copyright text-center my-auto">
              <span>Copyright © TradeLogs 2025. All rights reserved.</span>
            </div>
          </div>
      </footer>
    </div>
  )
}

export default Privacy;