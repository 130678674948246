import React, { useState } from "react";
import { getGapData, getFiveMinData } from "../modules/backtest";
import { Line } from 'react-chartjs-2';
import { connect } from "react-redux";

const GapStats = props => {
  const [intraPrices, setIntraPrices] = useState([[], [], []])
  const [intraTimes, setIntraTimes] = useState([])
  const [chartTicker, setChartTicker] = useState("")
  const [gapStats, setGapStats] = useState({})
  const [chartDate, setChartDate] = useState(null)

  const runTest = async (e) => {
    setIntraPrices([[], [], []])
    e.preventDefault();
    setChartTicker((e.target.testTicker.value).toUpperCase())

    let mostRecentGapDate = ""
    let t = e.target.testTicker.value
    let response2 = await getGapData(t);
    let newArray;

    let grouped = {
      gapCount: 0,
      gapPercents: 0,
      spikes: 0,
      closesOpen: [0, 0, 0, 0], //[Above open count, below open count, above open gain, below open gain]
      ranges: 0,
      day2UpDown: [0, 0, 0, 0] // [Up count, Down count, Up Avg, Down Avg]
    }

    const sortIntraDay = (intraData) => {
      
      const newArray = intraData.reverse();
      let pv = 0;
      let cumulativeVolume = 0;
    
      const pricesTimes = {
        PreMark: [],
        Main: [],
        VWAP: [],
        Labels: [],
        SMA: []
      };
    
      const smaArray = [];
    
      for (let i = 2; i < newArray.length - 1; i++) {
        const time = newArray[i][0];
        const isBeforeMarketOpen = time < "09:30:00";
        const currentPrice = newArray[i][1]['4. close'];
  
        if (isBeforeMarketOpen) {
          pricesTimes['PreMark'].push(currentPrice);
          pricesTimes['Main'].push(currentPrice);
        } else {
          pricesTimes['Main'].push(currentPrice);
        }
  
        pricesTimes['Labels'].push(time);
  
        // VWAP CALCULATION
        
        pv += ((Number(newArray[i][1]['2. high']) + Number(newArray[i][1]['3. low']) + Number(currentPrice)) / 3) * Number(newArray[i][1]['5. volume']);
        cumulativeVolume += Number(newArray[i][1]['5. volume']);
        pricesTimes['VWAP'].push((pv / cumulativeVolume).toFixed(2));
  
        // SMA CALCULATION
        
        if (smaArray.length < 50) {
          smaArray.push(currentPrice);
        } else {
          const value = smaArray.reduce((a, b) => a + b, 0) / 50;
          pricesTimes['SMA'].push(value);
          smaArray.shift();
        }
      }
    
      setIntraPrices([pricesTimes['PreMark'], pricesTimes['Main'], pricesTimes['VWAP'], pricesTimes['SMA']]);
      setIntraTimes(pricesTimes['Labels']);
      setSearches(pricesTimes);
    };
    

    const fiveMinData = async (t, recentDateArray, mostRecentGapDate) => {
      let data = await getFiveMinData(t, recentDateArray) 
      if (data.status === 200) {
        let intraData = []
        for (let key in data.data['Time Series (5min)']) {
          if (key.includes(mostRecentGapDate)) {
            const time = key.split(" ")[1];
            intraData.push([time, data.data['Time Series (5min)'][key]])
          }
        }
      sortIntraDay(intraData)
      } else {
        alert("Problem retrieving data");
      }
    }

    const tickerDataReceived = () => {
      for (let i = 1; i < newArray.length; i++) {
        const variables = {
          open: Number(newArray[i][1]["1. open"]),
          currentDayClose: Number(newArray[i][1]["4. close"]),
          highOfDay: Number(newArray[i][1]["2. high"]),
          volume: Number(newArray[i][1]["5. volume"]),
          previousDayClose: Number(newArray[i - 1][1]["4. close"]),
        };
    
        variables.gapPercent = ((variables.open - variables.previousDayClose) / variables.previousDayClose) * 100;
    
        if (variables.gapPercent > 19 && variables.volume > 900000) {
          mostRecentGapDate = newArray[i][0];
          variables.closeBelowOpen = variables.open > variables.currentDayClose;
    
          if (newArray[i + 1] !== undefined) {
            const nextDayOpen = Number(newArray[i + 1][1]["1. open"]);
            variables.day2 = ((nextDayOpen - variables.currentDayClose) / variables.currentDayClose) * 100;
            variables.day2 > 0 ? (grouped.day2UpDown[2] += variables.day2) && grouped.day2UpDown[0]++ : (grouped.day2UpDown[3] += variables.day2) && (grouped.day2UpDown[1]++);
          }
    
          grouped.gapCount++;
          grouped.gapPercents += variables.gapPercent;
          grouped.spikes += ((variables.highOfDay - variables.open) / variables.open) * 100;
          grouped.ranges += variables.highOfDay - Number(newArray[i][1]["3. low"]);
    
          if (!variables.closeBelowOpen) {
            grouped.closesOpen[0]++;
            grouped.closesOpen[2] += ((variables.currentDayClose - variables.open) / variables.open) * 100;
          } else {
            grouped.closesOpen[1]++;
            grouped.closesOpen[3] += ((variables.currentDayClose - variables.open) / variables.open) * 100;
          }
        }
      }

      setChartDate(mostRecentGapDate)

      const recentDateArray = mostRecentGapDate.split("-")

      if (mostRecentGapDate) {
        fiveMinData(t, recentDateArray, mostRecentGapDate)
      } else {
        alert("No Gaps over 20% to chart")
      }
    }

    if (response2.data["Time Series (Daily)"]) {
      newArray = Object.entries(response2.data["Time Series (Daily)"])
      newArray = newArray.reverse()
      tickerDataReceived()
    } else {
      alert("Could not find ticker")
    }

    let stats = {
      gapCount: grouped['gapCount'],
      avgGapPercent: (grouped['gapPercents']/grouped['gapCount']).toFixed(2),
      avgSpike: (grouped['spikes']/grouped['gapCount']).toFixed(2),
      closesAboveOpenCount: grouped['closesOpen'][0],
      closeAboveOpen: (grouped['closesOpen'][2]/grouped['closesOpen'][0]).toFixed(2),
      closeBelowOpen: (grouped['closesOpen'][3]/grouped['closesOpen'][1]).toFixed(2),
      avgRange: (grouped['ranges']/grouped['gapCount']).toFixed(2),
      day2UpCount: grouped['day2UpDown'][0],
      day2DownCount: grouped['day2UpDown'][1],
      day2AvgUp: (grouped['day2UpDown'][2]/grouped['day2UpDown'][0]).toFixed(2),
      day2AvgDown: (grouped['day2UpDown'][3]/grouped['day2UpDown'][1]).toFixed(2)
    }
    setGapStats(stats)
    
    const setSearches = (pricesTimes) => {
      props.setGapSearches([...props.gapSearches, [t, stats, pricesTimes]])
    }
  }

  const lineData = {
    labels: intraTimes,
    datasets: [
      {
        label: chartTicker,
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        pointRadius: 0,
        pointBackgroundColor: 'rgba(75,192,192,1)',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(75,192,192,1)',
        pointHitRadius: 10,
        pointBorderWidth: 2,
        data: intraPrices[1]
      },
      {
        label: "PreMarket",
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'grey',
        borderColor: 'darkgrey',
        pointRadius: 0,
        pointBackgroundColor: 'darkgrey',
        pointBorderColor: 'darkgrey',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'darkgrey',
        pointHoverBorderColor: 'darkgrey',
        pointHitRadius: 10,
        pointBorderWidth: 2,
        data: intraPrices[0]
      },
      {
        type: "line",
        label: "VWAP",
        fill: false,
        lineTension: 0.1,
        borderColor: 'orange',
        pointHoverBackgroundColor: "orange",
        pointHoverBorderColor: "orange",
        pointBackgroundColor: "orange",
        pointBorderColor: "orange",
        pointRadius: 0,
        pointHoverRadius: 3,
        pointHitRadius: 10,
        pointBorderWidth: 2,
        data: intraPrices[2]
      },
      {
        type: "line",
        label: "50SMA",
        fill: false,
        lineTension: 0.1,
        borderColor: 'rgb(0, 0, 255)',
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBorderWidth: 2,
        data: intraPrices[3]
      }
    ]
  };

  const lineOptions = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        time: {
          unit: 'date'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 20
        }
      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 6,
          padding: 10,
          // Include a dollar sign in the ticks
          callback: function(value, index, values) {
            return '$' + Intl.NumberFormat().format(value);
          }
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }],
    },
    legend: {
      display: false
    }
  }

  const showStats = (gapEntry) => {
    props.gapSearches.forEach(item => {
      if (item[0] === gapEntry) {
        setIntraPrices([item[2]['PreMark'], item[2]['Main'], item[2]['VWAP'], item[2]['SMA']])
        setIntraTimes(item[2]['Labels'])
        setChartTicker(item[0].toUpperCase())
        setGapStats(item[1])
      }
    })
  }

  let gapSearches = []
  if (props.gapSearches && props.gapSearches.length > 0) {
    gapSearches = props.gapSearches.map(entry => (
      <div key={entry[0]}>
        <h6 
          onClick={() => showStats(entry[0])}
          id="gapStatList"
        >
          {/* {gapSearchShow !== null && gapSearchShow[0] === entry[0] && (
            <i id="gap-show-arrow" className="angle double right icon"></i>
          )} */}
          <a class="dropdown-item" href>
            {entry[0].toUpperCase()}
          </a>
        </h6>
      </div>
    ));
  }

  // Summary message
  let content;
  switch (true) {
    case gapStats["gapCount"] < 6:
      content = (
        <>
          <div className="mt-4 text-left small">
            With only {gapStats["gapCount"]} observations, the probability estimate is unreliable. A more robust analysis would require additional samples to draw meaningful conclusions.
          </div>
          <div className="mt-2 text-left small">
            <span className="text-uppercase">Note:</span> Despite results, Volume and Demand should always be strongly considered.
          </div>
        </>
      );
      break;
    case gapStats["gapCount"] >= 6 && gapStats["gapCount"] < 9:
      content = (
        <>
          <div className="mt-4 text-left small">
            With {gapStats["gapCount"]} observations, the probability improves its reliability. A robust analysis requires more samples to draw meaningful conclusions.
          </div>
          <div className="mt-2 text-left small">
            <span className="text-uppercase">Note:</span> Despite results, Volume and Demand should always be strongly considered.
          </div>
        </>
      );
      break;
      case gapStats["gapCount"] > 9:
      content = (
        <>
          <div className="mt-4 text-left small">
            With {gapStats["gapCount"]} observations, the probability improves its reliability.
          </div>
          <div className="mt-2 text-left small">
            <span className="text-uppercase">Note:</span> Despite results, Volume and Demand should always be strongly considered.
          </div>
        </>
      );
      break;
    default:
      content = null; // Show nothing
  }

  
  return (
    <div class="container-fluid">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Gap Statistics</h1>
      </div>
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          {/* <!-- Topbar Search --> */}
          <form onSubmit={runTest} class=" d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
              <div class="input-group">
                  <input 
                    required
                    name="testTicker"
                    type="text" 
                    class="form-control bg-light border-0 text-uppercase text-xs" 
                    placeholder="Ticker Symbol" 
                    aria-label="Search" 
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                  <button 
                    class="btn btn-primary" 
                    type="button" 
                    onClick={() => document.querySelector('#hiddenSubmit').click()} // Use an inline function here
                  >
                    <i class="fas fa-search fa-sm"></i>
                  </button>
                  <input id="hiddenSubmit" type="submit" style={{display: "none"}} />
                </div>
              </div>
          </form>
          
          <ul class="navbar-nav ml-auto">
            {/* <!-- Nav Item - User Information --> */}
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="mr-2 d-none d-lg-inline text-gray-600 small">Reload Previous</span>
              </a>
              {/* <!-- Dropdown - User Information --> */}
              <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                  {gapSearches}
              </div>
            </li>
          </ul>
      </nav>
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          {/* <!-- Area Chart --> */}
          <div class="card shadow mb-4">
              <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-verydark">{chartTicker} Line Chart</h6>
              </div>
              <div class="card-body">
                {!chartTicker ? (
                  <div>
                    <h6 class="text-uppercase text-xs">Search something</h6>
                  </div>
                 ) : (
                  <div class="chart-area"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                    <Line
                      data = {lineData}
                      options = {lineOptions}
                    />
                  </div>
                 )}
                  <hr/>
                  <div class="mt-4 text-center small">
                    Recent Gap Chart {chartDate} (5min)
                      <span class="mr-2 ml-3">
                          <i class="fas fa-circle" style={{color: "rgb(49, 137, 137)"}}></i> Premarket
                      </span>
                      <span class="mr-2">
                          <i class="fas fa-circle" style={{color: "rgba(75,192,192,1)"}}></i> Reuglar Hours
                      </span>
                      <span class="mr-2">
                          <i class="fas fa-circle"  style={{color: "orange"}}></i> VWAP
                      </span>
                  </div>
              </div>
            </div>
          </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
            {/* <!-- Default Card Example --> */}
            <div class="card mb-4">
              <div class="card-header">
              {chartTicker} Current Day Probabilities
              </div>
              <div class="card-body">
              {chartTicker !== "" && gapStats["gapCount"] !== undefined && (
                  <>
                    <div className="row">
                      <h6 className="col-lg-6 text-left">Gaps Above 20%:</h6>
                      <h6 className="col-lg-6 text-right"> {gapStats["gapCount"]}</h6>

                      <h6 className="col-lg-6 text-left">Avg gap:</h6>
                      <h6 className="col-lg-6 text-right"> {gapStats["avgGapPercent"]}%</h6>

                      <h6 className="col-lg-6 text-left">Avg GapUp Spike Above Open:</h6>
                      <h6 className="col-lg-6 text-right"> {gapStats["avgSpike"]}%</h6>

                      <h6 className={`col-lg-6 text-left ${(gapStats["closesAboveOpenCount"] / gapStats["gapCount"]) > 0.50 ? 'border-left-success' : 'border-left-warning'}`}>Gap Up Closes Above Open:</h6>
                      <h6 
                        className={`col-lg-6 text-right ${(gapStats["closesAboveOpenCount"] / gapStats["gapCount"]) > 0.50 ? 'text-success' : 'text-warning'}`}
                      >
                        {gapStats["closesAboveOpenCount"]} ({((gapStats["closesAboveOpenCount"] / gapStats["gapCount"]) * 100).toFixed(2)}%)
                      </h6>
                      
                      <h6 className="col-lg-6 text-left border-left-success">Avg % close Above Open:</h6>
                      <h6 className="col-lg-6 text-right text-success"> + {gapStats["closeAboveOpen"]}%</h6>
                      
                      <h6 className="col-lg-6 text-left border-left-danger">Avg % close Below Open:</h6>
                      <h6 className="col-lg-6 text-right text-danger"> {gapStats["closeBelowOpen"]}%</h6>
                      
                      <h6 className="col-lg-6 text-left">Avg Gapper Range (Low to High):</h6>
                      <h6 className="col-lg-6 text-right"> $ {gapStats["avgRange"]}</h6>
                    </div>
                  </>
                )} 
                <hr/>
                  {content}
              </div>
            </div>
        </div>
        <div class="col-lg-6">
            {/* <!-- Default Card Example --> */}
            <div class="card mb-4">
                <div class="card-header">
                  {chartTicker} Day 2 Probabilities
                </div>
                <div class="card-body">
                  {chartTicker !== "" && gapStats["gapCount"] !== undefined && (
                    <>
                      <div className="row">
                        <h6 className="col-lg-6 text-left">Day 2 Gap up Count:</h6>
                        <h6 className="col-lg-6 text-right"> {gapStats["day2UpCount"]} ({((gapStats["day2UpCount"]/gapStats["gapCount"])*100).toFixed(2)}%)</h6>

                        <h6 className="col-lg-6 text-left">Day 2 Avg Gap up:</h6>
                        <h6 className="col-lg-6 text-right"> {gapStats["day2AvgUp"]}%</h6>

                        <h6 className="col-lg-6 text-left">Day 2 Gap Down Count:</h6>
                        <h6 className="col-lg-6 text-right"> {gapStats["day2DownCount"]}</h6>

                        <h6 className="col-lg-6 text-left">Day 2 Avg Gap Down:</h6>
                        <h6 className="col-lg-6 text-right"> {gapStats["day2AvgDown"]}%</h6>
                      </div>
                    </>
                  )} 
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    gapSearches: state.gapSearches
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setGapSearches: data => {
      dispatch({ type: "GAP_SEARCHES", payload: data })
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GapStats);