import React, { useState } from "react";
import { connect } from "react-redux";

const Trades = ({ savedTrades }) => {
  const [tradesArray, setTradesArray] = useState([]);
  const [stats, setStats] = useState({ Wins: 0, PnL: 0, Largest: 0, Smallest: 0 });

  const searchHistory = (e) => {
    e.preventDefault();
    const ticker = (e.target.ticker.value).toUpperCase();
    const newArray = savedTrades.data.filter((trade) => trade.Ticker === ticker);
    if (newArray.length !== 0) {
      convertTime(newArray);
      findStats(newArray);
    } else {
      alert(`No trade history for ${ticker}!`);
      setTradesArray([]);
    }
  };

  const convertTime = (newArray) => {
    newArray.forEach((i) => {
      let totalSeconds = i.TimeStamp * 24 * 60 * 60;
      let hours = Math.floor(totalSeconds / 3600);
      let minutes = Math.floor((totalSeconds % 3600) / 60);
      let seconds = Math.round(totalSeconds % 60);

      i.TimeStamp = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    
       //Change date formats
      let excelDate = i.Date;
      let epoch = new Date(1900, 0, 1); // January 1, 1900
      const actualDate = new Date(epoch.getTime() + (excelDate - 2) * 86400000);

      //Short numerical Date
      const year = actualDate.getFullYear(); // Get year
      const month = String(actualDate.getMonth() + 1).padStart(2, '0'); // Get zero-padded month
      const day = String(actualDate.getDate()).padStart(2, '0'); // Get zero-padded day

      const formattedDate = `${year}/${month}/${day}`; // Format date
      i.Date = formattedDate
    });
    setTradesArray(newArray)
  }

  const findStats = (trades) => {
    const newStats = trades.reduce(
      (accumulator, trade) => {
        const grossProfit = trade.GrossProfit;
        if (grossProfit > 0) {
          accumulator.Wins++;
          accumulator.PnL += grossProfit;
          accumulator.Largest = Math.max(accumulator.Largest, grossProfit);
        } else {
          accumulator.PnL += grossProfit;
          accumulator.Smallest = Math.min(accumulator.Smallest, grossProfit);
        }
        return accumulator;
      },
      { Wins: 0, PnL: 0, Largest: 0, Smallest: 0, Ticker: trades[0].Ticker}
    );
    setStats(newStats);
  };

  return (
    <div class="container-fluid">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-verydark">History</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_length" id="dataTable_length">
                    <label>Show 
                      <select name="dataTable_length" aria-controls="dataTable" class="custom-select custom-select-sm form-control form-control-sm">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select> entries
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div id="dataTable_filter" class="dataTables_filter">
                    <form onSubmit={searchHistory}>
                      <label>
                        <input class="form-control form-control-sm" aria-controls="dataTable" placeholder="Ticker" required type="text" name="ticker" id="ticker" />
                      </label>
                      <button className="btn btn-primary ml-2" id="loadChart" type="submit">
                        Search
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <table class="table table-bordered dataTable" id="dataTable" width="100%" cellspacing="0" role="grid" aria-describedby="dataTable_info">
                    <thead>
                        <tr role="row">
                          <th class="sorting sorting_asc" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending">Ticker</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending">Date</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Age: activate to sort column ascending">Time</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Salary: activate to sort column ascending">Side</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Salary: activate to sort column ascending">Shares</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Start date: activate to sort column ascending">Gross PnL</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Salary: activate to sort column ascending">Net PnL</th>
                        </tr>
                    </thead>
                    <tbody>
                      {tradesArray.length > 0 && (
                        tradesArray.map(ticker => {
                          return (
                          <>
                            <tr class="odd">
                              <td>{ticker.Ticker}</td>
                              <td>{ticker.Date}</td>
                              <td>{ticker.TimeStamp}</td>
                              <td>{ticker.Side}</td>
                              <td>{ticker.SharesTraded}</td>
                              <td>{(ticker.GrossProfit).toFixed(2)}</td>
                              <td>{(ticker.NetProfit).toFixed(2)}</td>
                            </tr>
                          </>
                          )
                        })
                      )}

                    {/* {tradesArray.length > 0 && (
                      <>
                        <tr class="odd">
                            <td>{stats.Ticker}</td>
                            <td>{tradesArray.length}</td>
                            <td>{stats.Wins}</td>
                            <td>${stats.PnL}</td>
                            <td>${stats.Largest}</td>
                            <td>${stats.Smallest}</td>
                          </tr>
                      </>
                    )} */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-5 mb-3">
                  {tradesArray.length > 0 && <div class="dataTables_info" id="dataTable_info" role="status" aria-live="polite">{tradesArray.length} Entries</div>}                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    savedTrades: state.savedTrades,
  };
};

export default connect(mapStateToProps)(Trades);